import ApiService, { Params } from '@/api/ApiService';

export default {
  create(payload: any) {
    return ApiService.post(`/surveys`, payload);
  },
  createStep(id: string, index: number, payload: any) {
    return ApiService.post(`/surveys/${id}/steps/${index}`, payload);
  },
  findAll(params: Params) {
    const config = {
      params,
    };
    return ApiService.get(`/surveys`, config);
  },
  findAllSamples() {
    return ApiService.get(`/surveys/samples`);
  },
  createSample(id: string, params: Params) {
    const config = {
      params,
    };
    return ApiService.post(`/surveys/${id}/samples`, {}, config);
  },
  overview(params: Params) {
    const config = {
      params,
    };
    return ApiService.get(`/surveys/overview`, config);
  },
  findAllMultipleChoiceAnswers(
    surveyId: string,
    stepId: string,
    params: Params,
  ) {
    const config = {
      params,
    };
    return ApiService.get(
      `/surveys/${surveyId}/steps/multiple-choice-answers/${stepId}`,
      config,
    );
  },
  findAllMultipleChoiceAnswersSegments(
    surveyId: string,
    stepId: string,
    params: Params,
    paginationParams: Params,
  ) {
    const config = {
      params: paginationParams,
    };
    return ApiService.post(
      `/surveys/${surveyId}/steps/multiple-choice-answers/${stepId}/find-all-segments`,
      params,
      config,
    );
  },
  findAllRatingScaleAnswers(surveyId: string, stepId: string, params: Params) {
    const config = {
      params,
    };
    return ApiService.get(
      `/surveys/${surveyId}/steps/rating-scale-answers/${stepId}`,
      config,
    );
  },
  findAllRatingScaleAnswersSegments(
    surveyId: string,
    stepId: string,
    params: Params,
    paginationParams: Params,
  ) {
    const config = {
      params: paginationParams,
    };
    return ApiService.post(
      `/surveys/${surveyId}/steps/rating-scale-answers/${stepId}/find-all-segments`,
      params,
      config,
    );
  },
  aggregateRatingScaleResponsesForSegment(surveyId: string, params: Params) {
    const config = {
      params,
    };
    return ApiService.get(
      `/surveys/${surveyId}/steps/rating-scale-answers/aggregate-responses-for-segment`,
      config,
    );
  },
  aggregateMultipleChoiceResponsesForSegment(surveyId: string, params: Params) {
    const config = {
      params,
    };
    return ApiService.get(
      `/surveys/${surveyId}/steps/multiple-choice-answers/aggregate-responses-for-segment`,
      config,
    );
  },
  findOne(id: string) {
    return ApiService.get(`/surveys/${id}`);
  },
  findOneByShareableLinkId(id: string) {
    return ApiService.get(`/surveys/${id}/shareable-link`);
  },
  duplicateOne(id: string, payload: any, params?: Params) {
    return ApiService.post(`/surveys/${id}/duplicate`, payload, { params });
  },
  update(id: string, payload: any) {
    return ApiService.patch(`/surveys/${id}`, payload);
  },
  upgrade(id: string, params: Params) {
    const config = {
      params,
    };
    return ApiService.patch(`/surveys/${id}/upgrade`, {}, config);
  },
  uploadImage(id: string, payload: any, params: Params) {
    const config = {
      params,
      headers: { 'Content-Type': 'multipart/form-data' },
    };
    return ApiService.post(`/surveys/${id}/upload`, payload, config);
  },
  updateOrder(id: string, payload: any) {
    return ApiService.put(`/surveys/${id}/steps`, payload);
  },
  updateStep(id: string, stepId: string, payload: any) {
    return ApiService.patch(`/surveys/${id}/steps/${stepId}`, payload);
  },
  updateStepImage(id: string, stepId: string, payload: any) {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };
    return ApiService.post(
      `/surveys/${id}/steps/${stepId}/image`,
      payload,
      config,
    );
  },
  replaceStep(id: string, stepId: string, payload: any) {
    return ApiService.put(`/surveys/${id}/steps/${stepId}`, payload);
  },
  remove(id: string) {
    return ApiService.delete(`/surveys/${id}`);
  },
  removeStep(id: string, stepId: string) {
    return ApiService.delete(`/surveys/${id}/steps/${stepId}`);
  },
  duplicateStep(surveyId: string, stepId: string) {
    return ApiService.post(`/surveys/${surveyId}/steps/${stepId}/duplicate`);
  },
  addMember(surveyId: string, payload: any) {
    return ApiService.post(`/surveys/${surveyId}/members`, payload);
  },
  addMemberByShareableLink(surveyId: string) {
    return ApiService.post(`/surveys/${surveyId}/members/shareable-link`);
  },
  updateMember(surveyId: string, memberId: string, payload: any) {
    return ApiService.patch(
      `/surveys/${surveyId}/members/${memberId}`,
      payload,
    );
  },
  removeMember(surveyId: string, memberId: string) {
    return ApiService.delete(`/surveys/${surveyId}/members/${memberId}`);
  },
  removeParticipants(surveyId: string) {
    return ApiService.delete(`/surveys/${surveyId}/participants`);
  },
  updatePath(surveyId: string, payload: any) {
    return ApiService.patch(`/surveys/${surveyId}/path`, payload);
  },
  export(surveyId: string, params: Params) {
    const config = { params };
    return ApiService.get(`surveys/${surveyId}/export`, config);
  },
  getGoogleSheetsAuthPortal(surveyId: string) {
    return ApiService.post(`/surveys/${surveyId}/google-sheets/`);
  },
  addGoogleSheetsIntegration(surveyId: string, params: Params) {
    const config = { params };
    return ApiService.patch(`/surveys/${surveyId}/google-sheets/`, {}, config);
  },
  deleteGoogleSheetsIntegration(surveyId: string) {
    return ApiService.delete(`/surveys/${surveyId}/google-sheets/`);
  },
  addImagesToMultipleChoiceAnswers(
    surveyId: string,
    stepId: string,
    payload: any,
  ) {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };
    return ApiService.post(
      `/surveys/${surveyId}/steps/multiple-choice-answers/${stepId}/images`,
      payload,
      config,
    );
  },
  addMultipleChoiceStepAnswers(surveyId: string, stepId: string, payload: any) {
    return ApiService.post(
      `/surveys/${surveyId}/steps/multiple-choice-answers/${stepId}/`,
      payload,
    );
  },
  updateMultipleChoiceStepAnswer(
    surveyId: string,
    stepId: string,
    answerId: string,
    payload: any,
  ) {
    return ApiService.patch(
      `/surveys/${surveyId}/steps/multiple-choice-answers/${stepId}/${answerId}`,
      payload,
    );
  },
  removeMultipleChoiceStepAnswer(
    surveyId: string,
    stepId: string,
    answerId: string,
  ) {
    return ApiService.delete(
      `/surveys/${surveyId}/steps/multiple-choice-answers/${stepId}/${answerId}`,
    );
  },
  updateNotificationSettings(surveyId: string, payload: any) {
    return ApiService.patch(`/surveys/${surveyId}/notifications`, payload);
  },
  findAllCategoricalEnrichmentFieldResults(
    surveyId: string,
    enrichmentFieldId: string,
    params: Params,
  ) {
    return ApiService.get(
      `/surveys/${surveyId}/enrichment-fields/${enrichmentFieldId}/category-results`,
      { params },
    );
  },
};
